import request from '@/utils/request'

// 分页查询 新闻
export function newsPaging(data) {
  return request({
    url: `/df-it-news-entity/paging`,
    method: 'post',
    data: data
  })
}

// 获取详情 新闻
export function newsView(id) {
  return request({
    url: `/df-it-news-entity/view/${id}`,
    method: 'get',
  })
}

// 分页查询 招聘通知
export function recruitmentPaging(data) {
  return request({
    url: `/df-it-recruitment-entity/paging`,
    method: 'post',
    data: data
  })
}

// 获取详情 招聘通知
export function recruitmentView(id) {
  return request({
    url: `/df-it-recruitment-entity/view/${id}`,
    method: 'get',
  })
}


// 分页查询 招标公告
export function tenderPaging(data) {
  return request({
    url: `/df-it-tender-entity/paging`,
    method: 'post',
    data: data
  })
}

// 获取详情 招标公告
export function tenderView(id) {
  return request({
    url: `/df-it-tender-entity/view/${id}`,
    method: 'get',
  })
}

// 获取最新 内部公告
export function selectNew(data) {
  return request({
    url: `/df-it-notice-internal-entity/selectNew`,
    method: 'post',
    data: data
  })
}