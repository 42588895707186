import { JSEncrypt } from "@/assets/baseJs/jsencrypt.js"
import CryptoJS from "crypto-js";
//RSA解密
export function decryptCode(params){
  //实例化JSEncrypt
  let jse = new JSEncrypt();
  //使用前端私钥解密（私钥由服务端生成）
  let privKey = `MIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQCqNu3hoi+pDfAyOrTTgkevhSCHgVJ57Lk2rUBj5K2e+2RPgVc/GTaNTsBzkTtBsQ5ulWtgfwWSTVo6767Gf2yatotiMJSq/Xo0lbO6+9/LWInJIwo06ckRQcArbxj6h9JjFkZ1mEkhSO43toV8Amw0TNYxIdBNFgB4FtHVrf4BRuTHhtmzQq2TGPf/8o8gKWGGDXchMPEuk/IYeBH2p3/OgkBFqvMvMYUIkoTN7QEBSZ99ACc09zCwEJcPDZl6dOSqs6w7UXDsA961ZIMrkoqjgd1myEN6E8Q8KeJ7/rVf0geM7eBU3dn0cP/f0oHNrqApfJplDDkxso7VpInwmgXJAgMBAAECggEBAJEA1km/NsbXnuXLaTw94f++c2W8X5yKc4YGtbHjn5yuIFgyoTaZrgVXL0/ESyy9PsQp+h1sWDy4NMO8Z7Gl38k09SyioiPAaBjf5wrFg3LhbA+zEADfoHBZCPJ3ZFoY1ruewEtxfesjC/zeOnzYO4Gaa2XTnnXs5l1nYz6bSKWgbOapitMX9ydjWKStcPKqm5nZlDn9/Aitpp769FJTlnRM2Kv7AeZAxJeaKxMID4vL0iNiDI6gN+ut1MEMcN63ecrRl8zNlsITktOD6+sW9nS0LYMjdUcQo2xM6CJpfngiXvC4iQvHv7WbPoQVW52kaj9ouuqQmn79xeHYlUSQeWECgYEA9addFiFz8Cg4IGUjcx2sO8y3S1Hl6Lq3qIf8bQnaxBTkPbxnoqOfQqc9vby+6yBHR6mYg3gIvGybCWBHwbzNMchp5yI9HvgDUHEbm9uyW9K41yfzTOihgg8APmhzGTikaTsaKgZNfTll8eJq6THjVlWZWHqDOmFXd7OboT9hQY0CgYEAsWIuUvpeRPRFeNy1khwFqT99Q/VJTLUAW+JxheKvVBZo18mrk6s+bRPHv14Ngi0lrZWqtt/JJS9gYq+68sVPnv3I5Cqd1jSNnYDhndmf7QbJ4FPm1b5reE6GLjCurxCRjkZrynZvOL9ue5eJgsAODTN10nMWya5EYTTXffxngC0CgYEA7GU5jCF3K4XYtTv+yDmm1Akl3mKap8J/GLx8KwYPsyAToGFdLcLdcMv1+0/WPfha/9hx/xoU9hZhf71acFLa9+xQFW/6d4Hd1pxyICNkWT7IR6yXUsyPF9SNvIDROlUqymVowUY9baM9um7TyUML7boEDoQfIqAoBvYdbyfJ1MUCgYEAj7EJ95vre44pLOQVjdt3Vogg0ElF7IZ/WO+RqLRd9Q8GNS+f9sp0kmXAnKvy423xJLGmDegwiBfdy3OxTHI7sn7BGtvO6jO+OEWr/nPJUnf6VueAictsQZPSrF4xU41na8puDR9tr5mbRTEXarxrZZQ3MJiccBnkOJ5Wpi8uJK0CgYAwCWQW9hvHfvN3qneqzY/QS4bw9EhOQi5LE8nT6wbQ3eUTv4y/c8BmT87giAhUKLBGKrLgphQLhYn4RNY5ZLAKPRcz2JSvITm3hocVghKgatrTTu+4yOzaOmXzhPchac7UnEXwL3dHfa5AziF9q+JVrLWxSSyGAx2ei6gdytf04A==`

  jse.setPrivateKey(privKey);
  let data = jse.decryptLong(params); //解密
  // console.log(data,'解密')
  return data;
}　

// RSA加密
export function encryptCode(params) {
   //实例化JSEncrypt
  let jse = new JSEncrypt();
  const pubKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqjbt4aIvqQ3wMjq004JHr4Ugh4FSeey5Nq1AY+StnvtkT4FXPxk2jU7Ac5E7QbEObpVrYH8Fkk1aOu+uxn9smraLYjCUqv16NJWzuvvfy1iJySMKNOnJEUHAK28Y+ofSYxZGdZhJIUjuN7aFfAJsNEzWMSHQTRYAeBbR1a3+AUbkx4bZs0Ktkxj3//KPIClhhg13ITDxLpPyGHgR9qd/zoJARarzLzGFCJKEze0BAUmffQAnNPcwsBCXDw2ZenTkqrOsO1Fw7APetWSDK5KKo4HdZshDehPEPCnie/61X9IHjO3gVN3Z9HD/39KBza6gKXyaZQw5MbKO1aSJ8JoFyQIDAQAB`;

  jse.setPublicKey(pubKey);
  let data = jse.encryptLong(params);
  // console.log(data)
  return data
}

// AES解密 (ECB)
export function decryptAES(word,keyStr) {
  keyStr = 'cqdefang!welcome'
  var key = CryptoJS.enc.Utf8.parse(keyStr)// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
//AES加密
export function encryptAES (word, keyStr) { // word, keyStr第一个参数是加密的字段名字  第二个是key值（16位）
  // const keyStr = keyStrs // 密文（密钥）
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return encrypted.toString()
}