import axios from 'axios'
// import { Notification, Message } from 'element-ui'
import { Indicator, Toast } from 'mint-ui'
import errorCode from '@/utils/errorCode'
import { decryptAES, encryptCode, encryptAES } from '@/utils/jsencryptKey'
import { randomWord } from "@/utils"
axios.defaults.headers['Content-Type'] = 'application/json'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: 'http://192.168.3.26:8002/',
  // baseURL: 'http://47.108.234.187:8002',
  baseURL: window.dasConfig.baseUrl,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  console.log(config)
  config.headers['accept-language'] = 'zh';
  try {
    config.headers['Authorization'] = config.data.Authorization||''
  } catch (error) {
    
  }
 
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  // if (getToken() && !isToken) {
  //   config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  // }
  Indicator.open({
    text:'加载中...',
    spinnerType: 'fading-circle'
  });
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  // application/json 才加密解密
  if(config.headers[ 'Content-Type']=='application/json'){
    // encryptAES
    console.log(config.data, '传参')
    const keyStr = randomWord(false,16)
    config.headers['dfc'] = encryptCode(keyStr)
    config.data = {"encryptStr": config.data ? encryptAES(JSON.stringify(config.data),keyStr) :''}
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  const { config, data } = res;
  console.log(res)
  Indicator.close();
  var responseData
    if(data.code !== 200){
      responseData = data
    }else{
      responseData = config.isBlob ? data.data : JSON.parse(decryptAES(data.data))
    }
  console.log(responseData)
    // 未设置状态码则默认成功状态
    // console.log(res.headers.authorization)
    let completeData = false
    // if(res.headers.authorization){
    //   setToken(res.headers.authorization)
    // }
    try {
      completeData = config.completeData||false
    } catch ( error ) { 
      console.log(error)
    }
    
    const code = data.code;
    // 获取错误信息
    const msg = errorCode[code] || responseData.msg || errorCode['default']
    if (code === 692 ) {
      // store.dispatch('LogOut').then(() => {
      //   location.href = '/index';
      // })
    } else if (code === 403) {
      // MessageBox.confirm(res.data.msg, '系统提示', {
      //     confirmButtonText: '重新登录',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }
      // ).then(() => {
       
      // }).catch(() => {});
      return Promise.reject('error')
    } else if (code === 500) {
      // Message({
      //   message: msg,
      //   type: 'error'
      // })
      Toast({
        message: data.msg,
        duration: 5000
      });
      return Promise.reject(new Error(data.msg))
    } else if (code !== 200) {
      // Notification.error({
      //   title: msg
      // })
      Toast({
        message: data.msg,
        duration: 5000
      });
      return Promise.reject('error')
    } else {
      // 返回参数
      // console.log(completeData,'completeData')
      // console.log(res.data,'返回参数')
      // if(completeData){
        return responseData
      // }else{
      //   return responseData.data
      // }
      
    }
  },
  error => {
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    // Message({
    //   message: message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    Toast({
      message: message,
      duration: 5000
    });
    return Promise.reject(error)
  }
)

export default service
